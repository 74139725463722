import { FaBeer } from 'react-icons/fa';
import epikoColoresLogo from './assets/epiko-colores-logo.png';
import epikoNaranjaBlanco from './assets/epiko-naranja-blanco.png';
import ContactsGrid from './components/ContactsGrid';
import './App.css';

function App() {
  return (
    <div className='bg-cover bg-center h-screen bg-gradient-to-b from-epiko-mid-blue to-epiko-dark-blue flex justify-center'>
      <div className='grid grid-cols-1 m-auto hx-auto w-auto max-w-4xl'>
        <div>
          <img src={epikoNaranjaBlanco} className="m-auto mb-5 hx-auto w-3/4" alt="company-logo" />
        </div>
        <ContactsGrid/>
      </div>
    </div>
  );
}

export default App;
