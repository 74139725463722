import '../App.css';
import IconPlusText from './IconPlusText';
import { FaPhoneVolume } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa6";
import { FaRegEnvelope } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa6";

function ContactsGrid() {
    let iconsStyle = 'w-4 h-4 me-2';
    return (
        <div className='mx-10 grid grid-cols-1 md:grid-cols-2'>
          <IconPlusText icon=<FaPhoneVolume className={iconsStyle}/> url='tel:+50689436983' text='¡Llámanos! +(506) 8960 - 2970'/>
          <IconPlusText icon=<FaWhatsapp className={iconsStyle}/> newPage='yes' url='https://wa.me/50689436983' text='¡Escríbenos por Whatsapp!'/>
          <IconPlusText icon=<FaRegEnvelope className={iconsStyle}/> url='mailto:info@epiko.cr' text='¡Escríbenos un email! info@epiko.cr'/>
          <IconPlusText icon=<FaFacebookF className={iconsStyle}/> newPage='yes' text='¡Encuéntranos en Facebook' url='https://www.facebook.com/people/%C3%89piko-Ingenier%C3%ADa-en-Construcci%C3%B3n/61555522919068/'/>
    
        </div>
    );
}

export default ContactsGrid;