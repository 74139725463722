import '../App.css';

function IconPlusText(props) {
    return (
        <a
            type='button'
            href={props.url}
            target={props.newPage? "_blank" : ""}
            className='
                text-white
                bg-epiko-light-blue
                rounded-full
                text-sky-400 inline-flex items-center justify-center
                font-bold
                hover:bg-epiko-orange text-sky-400
                py-2 px-4 me-2 mb-2
            '
            
        >
            {props.icon}
            {props.text}
        </a>
    );
}

export default IconPlusText;